import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setLogout } from "../../../redux/features/auth/authSlice";
import React from "react";
import { PermIdentityOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";

const AppHeader = () => {
  return (
    <div className="app-header">
      <div className="container page-container justify-between">
        <div className="logo-content">
          <Link to="/dashboard">
            <img src="/images/logo.png" alt="KodMarket" width={160} />
          </Link>
        </div>
        <div className="header-menu">
          <MyAccountMenu />
        </div>
      </div>
    </div>
  );
};

const MyAccountMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.auth.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(setLogout());
  };

  return (
    <>
      <Box className="my-account-menu">
        <Tooltip title="Hesabım">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 27, height: 27, backgroundColor: "#fff" }}>
              <PermIdentityOutlined sx={{ width: 18, color: "#001e26" }} />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose} className="profile-info">
          <div className="profile-name"> {user.name}</div>
          <div className="profile-email">{user.email}</div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("account/profile")}>
          Profil Bilgilerim
        </MenuItem>
        <MenuItem onClick={() => navigate("account/changePassword")}>
          Şifre Değiştir
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogout}>Çıkış Yap</MenuItem>
      </Menu>
    </>
  );
};

export default AppHeader;
