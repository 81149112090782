import AppLayout from "../layout/app-layout";
import AuthLayout from "../layout/auth-layout";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

function RouteInterceptor({ element, isAuthenticated, ...rest }) {
  return isAuthenticated ? element : <Navigate to={"/auth"} replace />;
}

export const Views = (props) => {
  const token = useSelector((state) => state.auth.accessToken);

  return (
    <Routes>
      <Route path={`/auth/*`} element={<AuthLayout />} />
      <Route
        path="/*"
        element={
          <RouteInterceptor isAuthenticated={token} element={<AppLayout />} />
        }
      ></Route>
    </Routes>
  );
};

export default Views;
