const overrideToLocaleString = Number.prototype.toLocaleString;

// eslint-disable-next-line no-extend-native
Number.prototype.formatTutarGoster = function () {
  let result = overrideToLocaleString.call(this, "tr-TR", {
    style: "currency",
    currency: "TRY",
  });
  return result.replace("₺", "");
};
