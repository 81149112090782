import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  moduleDialog: null,
  moduleRefresh: false,
};

const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    setModuleDialog: (state, action) => {
      state.moduleDialog = action.payload;
    },
    setModuleRefresh: (state, action) => {
      state.moduleRefresh = action.payload;
    },
  },
});

export const { setModuleDialog, setModuleRefresh } = moduleSlice.actions;

export default moduleSlice.reducer;
