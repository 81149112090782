import { Button, Tooltip } from "@mui/material";
import "./styles.scss";
import { getAllModule } from "../../../services/ModuleService";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import { useDispatch, useSelector } from "react-redux";
import CreateModuleDialog from "./createModuleDialog";
import {
  setModuleDialog,
  setModuleRefresh,
} from "../../../redux/features/modules/moduleSlice";
import { Link, useLocation } from "react-router-dom";

const AppModules = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [modules, setModule] = React.useState([]);
  const [selectedModuleId, setSelectedModuleId] = React.useState("");
  const moduleDialog = useSelector((state) => state.module.moduleDialog);
  const moduleRefresh = useSelector((state) => state.module.moduleRefresh);

  const moduleList = React.useCallback(() => {
    getAllModule().then((response) => setModule(response.data.model));
  }, []);

  React.useEffect(() => {
    moduleList();
  }, [moduleList]);

  React.useEffect(() => {
    if (moduleRefresh) {
      moduleList();
      dispatch(setModuleRefresh(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleList, moduleRefresh]);

  React.useEffect(() => {
    setSelectedModuleId(location.pathname.replace("/module/", ""));
  }, [location]);

  return (
    <div className="app-module-content">
      <div className="container page-container items-center">
        <div className="menu-module-list">
          {modules.map((module) => {
            return (
              <Link
                to={`module/${module.moduleId}`}
                key={module.moduleId}
                className={`module-item ${
                  selectedModuleId === module.moduleId ? "selected" : ""
                }`}
              >
                <div className="module-icon">
                  <PowerOutlinedIcon />
                </div>
                <div className="module-name">{module.name}</div>
              </Link>
            );
          })}
        </div>
        <Tooltip title="Yeni Entegrasyon Ekle">
          <Button
            className="module-create-button"
            onClick={() => dispatch(setModuleDialog({ moduleId: 0 }))}
          >
            <AddIcon />
          </Button>
        </Tooltip>
        {moduleDialog && <CreateModuleDialog />}
      </div>
    </div>
  );
};

export default AppModules;
