import "react-confirm-alert/src/react-confirm-alert.css";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
// import PageLoading from "components/shared-components/page-loading-components";
const Dashboard = React.lazy(() => import("./dashboard/dashboard"));
const Modules = React.lazy(() => import("./modules/modules"));

const routes = [
  {
    key: "dashboard",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    key: "module",
    path: "/module/:moduleId",
    element: <Modules />,
  },
];
const AppViews = (props) => {
  return (
    <Suspense fallback={<>----</>}>
      <Routes>
        {routes.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
        <Route path={`*`} element={<Dashboard />} />
      </Routes>
    </Suspense>
  );
};

export default AppViews;
