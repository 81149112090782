import React from "react";
import AppViews from "../../views/app-views/appViews";
import AppHeader from "./app-header";
import AppModules from "./app-modules";

const AppLayout = (props) => {
  return (
    <div className="app-container">
      <AppHeader />
      <AppModules />
      <div className="app-body">
        <div className="app-body-content">
          <AppViews />
          {/* <Notify /> */}
        </div>
      </div>
      {/* <AppFooter /> */}
    </div>
  );
};

export default AppLayout;
