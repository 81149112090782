import api from "./ApiService";

export function createModule(module) {
  return api.post(`/Module/Create`, module);
}

export function updateModule(module) {
  return api.put(`/Module/Update`, module);
}

export function deleteModule(moduleId) {
  return api.delete(`/Module/Delete/${moduleId}`);
}

export function getModule(moduleId) {
  return api.get(`/Module/Get/${moduleId}`);
}

export function getByModuleType(moduleTypes) {
  return api.post(`/Module/GetByModuleType`, moduleTypes);
}

export function getAllModule() {
  return api.get(`/Module/GetAll`);
}
