const Resources = Object.freeze({
  ModuleTypes: [
    {
      moduleType: "Knack",
      name: "Bayi Knack",
      category: "Xml",
      image: "/images/market-logo-knack.jpg",
    },
    {
      moduleType: "Trendyol",
      name: "Trendyol",
      category: "Pazaryeri",
      image: "/images/market-logo-trendyol.jpg",
    },
    {
      moduleType: "Shopier",
      name: "Shopier",
      category: "Eticaret",
      image: "/images/market-logo-shopier.jpg",
    },
  ],
  ShopierDispatchDurations: [
    {
      key: null,
      value: "Kapalı: Kargo teslim süresi gösterilmez",
    },
    {
      key: 1,
      value: "Sipariş ardından 1 iş günü içinde",
    },
    {
      key: 2,
      value: "Sipariş ardından 2 iş günü içinde",
    },
    {
      key: 3,
      value: "Sipariş ardından 3 iş günü içinde",
    },
  ],
  ProductIsMatches: [
    {
      key: null,
      label: "Tümü",
    },
    {
      key: true,
      label: "Eşleştirilen",
    },
    {
      key: false,
      label: "Eşleştirilmeyen",
    },
  ],
  ProductStockState: [
    {
      key: null,
      label: "Tümü",
    },
    {
      key: true,
      label: "Stokta Olanlar",
    },
    {
      key: false,
      label: "Stokta Olmayanlar",
    },
  ],
});

export const enumResource = (key) => Resources[key];
