import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const DialogComponent = (props) => {
  const {
    data,
    title,
    handleClose,
    handleSave,
    maxWidth,
    loading,
    closeButtonText,
    saveButtonText,
    hideSaveButton,
    className,
    customButton,
    saveDisabled,
  } = props;

  const onClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

  return (
    <Dialog
      open={data != null}
      fullWidth={true}
      maxWidth={maxWidth ?? "sm"}
      onClose={onClose}
      aria-labelledby="dialog-title"
      className={`dialog-container ${className ?? ""}`}
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {customButton && customButton}
        {!hideSaveButton && (
          <Button
            variant="contained"
            loading={loading}
            onClick={handleSave}
            disabled={saveDisabled ?? false}
            className="save-button"
            size="small"
          >
            {saveButtonText ?? "Kaydet"}
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={onClose}
          className="close-button"
          size="small"
        >
          {closeButtonText ?? "Vazgeç"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
