export const formOnChange = (name, value, setValues, setErrors) => {
  setValues((prevState) => ({
    ...prevState,
    [name]: value,
  }));

  if (setErrors) {
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  }
};

export const formOnBlur = (name, value, setErrors, errors) => {
  if (!value || value === "") {
    setErrors((prevState) => ({
      ...prevState,
      [name]: "Boş bırakmayınız",
    }));
  } else {
    if (errors[name]) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  }
};

export const validateProp = (propName, error, values) => {
  if (!values[propName] || values[propName] === "") {
    error[propName] = "Boş bırakmayınız!";
  }
};
