import { useDispatch, useSelector } from "react-redux";
import DialogComponent from "../../../components/dialog";
import {
  setModuleDialog,
  setModuleRefresh,
} from "../../../redux/features/modules/moduleSlice";
import { useSnackbar } from "notistack";
import { Grid, TextField } from "@mui/material";
import { enumResource } from "../../../utils/resources";
import React from "react";
import { formOnChange } from "../../../utils/formHelpers";
import { createModule } from "../../../services/ModuleService";

const CreateModuleDialog = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const moduleDialog = useSelector((state) => state.module.moduleDialog);
  const [values, setValues] = React.useState({
    moduleType: "",
    name: "",
  });

  const onSave = () => {
    createModule(values).then((response) => {
      if (response.data.isSuccess && response.data.model) {
        dispatch(setModuleDialog(null));
        dispatch(setModuleRefresh(true));
      } else {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      }
    });
  };

  return moduleDialog ? (
    <DialogComponent
      data={moduleDialog}
      maxWidth="sm"
      title={"ENTEGRASYON EKLE"}
      handleClose={() => dispatch(setModuleDialog(null))}
      handleSave={onSave}
      saveDisabled={!values.moduleType}
      className="create-module-dialog"
    >
      <Grid container rowSpacing={4}>
        <Grid container item spacing={2} marginTop={1} xs={12}>
          {enumResource("ModuleTypes").map((module) => {
            return (
              <Grid item xs={4} key={module.moduleType}>
                <div
                  className={`module-type-item ${
                    values.moduleType === module.moduleType ? "selected" : ""
                  }`}
                  onClick={() => {
                    formOnChange("moduleType", module.moduleType, setValues);
                    formOnChange("name", module.name, setValues);
                  }}
                >
                  <img src={module.image} alt={module.title} />
                  <div className="module-category">{module.category}</div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            size="small"
            fullWidth
            label="Başlık"
            value={values.name}
            onChange={(e) => formOnChange("name", e.target.value, setValues)}
          />
        </Grid>
      </Grid>
    </DialogComponent>
  ) : (
    <>--------------</>
  );
};

export default CreateModuleDialog;
